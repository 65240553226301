import { MineDevelopmentIcon } from "../components/icons/mine-development-icon";
import { COLOR_VALUE_MINE_DEVELOPMENT } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendMineDevelopment: TrendNodeSpec = {
  width: 159,
  height: 171,
  x: 534.5,
  y: 88,
  slug: 'mine-development',
  color: COLOR_VALUE_MINE_DEVELOPMENT,
  icon: {
    x: 58.78, y: 29, width: 41, height: 38,
    component: MineDevelopmentIcon,
  },
  bubble: {
    text: [
      { x: 55.1367, y: 135.75, text: 'Mine' },
      { x: 12.2539, y: 164.75, text: 'Development' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 612.66, y: 139.63,
    text: [
      { x: 624, y: 103.25, text: 'Mine' },
      { x: 624, y: 121.25, text: 'Development' },
    ],
  },
  topicNodes: [
    {
      slug: 'process-simulation',
      text: [ 
        { x: 589.103, y: 338.54, text: 'Process' },
        { x: 589.103, y: 353.54, text: 'Simulation' },
      ],
      bubble: { x: 567.055, y: 340.524 },
      path: "M612.126 140.075C612.126 140.075 549.435 265.325 567.275 343.575",
    },

    {
      slug: 'grade-engineering-block-model',
      text: [ 
        { x: 552.055, y: 482.74, text: 'Grade' },
        { x: 552.055, y: 497.74, text: 'Engineering' },
        { x: 552.055, y: 512.74, text: 'Block' },
        { x: 552.055, y: 527.74, text: 'Model' },
      ],
      bubble: { x: 538.355, y: 453.175 },
      path: "M612.125 140.075C612.125 140.075 486.225 332.745 537.505 455.645",
    },

    {
      slug: 'grade-engineering-studies',
      text: [ 
        { x: 428.895, y: 556.242, text: 'Grade' },
        { x: 428.895, y: 571.242, text: 'Engineering' },
        { x: 428.895, y: 586.242, text: 'Studies' },
      ],
      bubble: { x: 443.896, y: 521.234 },
      path: "M612.126 140.075C612.126 140.075 442.026 334.495 442.616 523.615",
    },

    {
      slug: 'strategic-planning-with-grade-engineering',
      text: [ 
        { x: 239.516, y: 516.74, text: 'Strategic' },
        { x: 241.127, y: 531.74, text: 'Planning' },
        { x: 271.039, y: 546.74, text: 'with' },
        { x: 259.525, y: 561.74, text: 'Grade' },
        { x: 219.711, y: 576.74, text: 'Engineering' },
      ],
      bubble: { x: 328.315, y: 512.615 },
      path: "M612.125 140.075C612.125 140.075 402.145 355.185 326.585 513.375",
    },

    {
      slug: 'lever-characterisation',
      text: [ 
        { x: 179.447, y: 429.45, text: 'Lever' },
        { x: 95.79, y: 444.45, text: 'Characterisations' },
      ],
      bubble: { x: 244.525, y: 431.325 },
      path: "M612.115 140.535C612.115 140.535 392.905 355.175 243.745 432.405",
    },

    {
      slug: 'spatial-to-temporal-divide',
      text: [ 
        { x: 143.533, y: 291.169, text: 'Spatial to' },
        { x: 144.236, y: 306.169, text: 'Temporal' },
        { x: 165.887, y: 321.169, text: 'Divide' },
      ],
      bubble: { x: 232.945, y: 315.544 },
      path: "M612.115 140.535C612.115 140.535 420.625 313.925 231.525 316.565",
    },


    {
      slug: 'short-term-planning',
      text: [ 
        { x: 256.908, y: 167.164, text: 'Short' },
        { x: 259.34, y: 182.164, text: 'Term' },
        { x: 234.277, y: 197.164, text: 'Planning' },
      ],
      bubble: { x: 298.315, y: 219.395 },
      path: "M612.115 140.535C612.115 140.535 421.615 269.705 297.865 220.535",
    },  

    {
      slug: 'activities',
      text: [ 
        { x: 378.623, y: 163.829, text: 'Activities' },
      ],
      bubble: { x: 410.491, y: 187.865 },
      path: "M612.115 140.535C612.115 140.535 487.955 205.355 409.405 188.855",
    },



  ],
};
