import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteHeaderLayout, classNames, useEntity, EntityGridDetailsLayout } from '@shapeable/web';
import { TrendAsideLayout } from '../entities/trend-aside-layout';
import { PageMainLayout } from '../entities/page-main-layout';
import { ExplorerProvider } from '../providers/explorer-provider';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
const cls = classNames('trend-details-layout');

// -------- Types -------->

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
});

const SiteHeaderStyles = breakpoints({
  base: css`
    
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
  desktop: css`
    padding: ${theme.UNIT(5)} 0 0;
  `,
});
const AsideStyles = breakpoints({
  base: css`
    
  `,
});

const MainStyles = breakpoints({
  base: css`
    
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(SiteHeaderLayout)`${SiteHeaderStyles}`,
    Layout: styled(EntityGridDetailsLayout).attrs(cls.attr('Layout'))`${LayoutStyles}`,
      Aside: styled(TrendAsideLayout)`${AsideStyles}`,
      Main: styled(PageMainLayout)`${MainStyles}`
};

export const PageLayoutThemeSupportingInfo: PageLayoutComponent = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  const { isShown } = useValueChainMap();

  return (
    <ExplorerProvider>
    <My.Container className={cls.name(className)}>
      <My.Header variant="light" />
      <My.Layout
        asideHeaderProps={{ showBubbleImage: false }}
        entity={entity}
        asideIsRevealed={isShown}
        aside={
          <My.Aside entity={entity} />
        }
        main={
          <My.Main entity={entity} />
        }
      />
      {children}
    </My.Container>
    </ExplorerProvider>
  )
};
