import React from 'react';
import { TrendThemeProvider } from './trend-theme-provider';
import { LinearNavigationProvider } from './linear-navigation-provider';
import { ActiveEntityProvider, SiteHeaderProvider, SiteSidebarProvider, useAuth } from '@shapeable/web';
import { PAGE_EXPLORER, WITH_AUTHENTICATION_REQUIRED_OPTIONS } from '../../data';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { COLOR_VALUE_MINERAL_PROCESSING } from '../../theme';

const ExplorerProviderComponent: React.FC<any> = ({ children }) => {
  const auth = useAuth();

  return (
    <ActiveEntityProvider value={PAGE_EXPLORER}>
    <SiteHeaderProvider value={{ 
      variant: 'light',
      color: 'dark',
    }}>
    <LinearNavigationProvider>
    <TrendThemeProvider>
    {children}
    </TrendThemeProvider>
    </LinearNavigationProvider>
    </SiteHeaderProvider>
    </ActiveEntityProvider>
  );

};

export const ExplorerProvider = withAuthenticationRequired(ExplorerProviderComponent, WITH_AUTHENTICATION_REQUIRED_OPTIONS);



