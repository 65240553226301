import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/web';
const cls = classNames('image-embed');
import Zoom from 'react-medium-image-zoom';

// -------- Types -------->

export type ImageEmbedProps = Classable & HasChildren & {
  src: string;
  alt?: string;
  title?: string;
}

export const ImageEmbedDefaultProps: Omit<ImageEmbedProps, 'src'> = {

};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    max-width: 800px;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const ImageEmbed: Shapeable.FC<ImageEmbedProps> = (props) => {
  const { className, src, alt, title } = props;

  return (
    <My.Container className={cls.name(className)}>
    <Zoom>
      <img src={src} alt={alt} title={title} />
    </Zoom>
    </My.Container>
  )
};

ImageEmbed.cls = cls;
ImageEmbed.defaultProps = ImageEmbedDefaultProps;