import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, OrganisationsProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query OrganisationsQuery {
    platform {
      allOrganisations {
        id name url slug __typename _entityTypeName
        openGraph {
          title date description { plain }
          image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
        }
        type { slug name }
        logo { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
        countries { id slug name }
        technologies { id slug name openGraph { image { url } } }
      }
    }
  }
`

export const OrganisationsProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allOrganisations' ) }>{children}</Provider>;
};