import { Entity } from "@shapeable/types";
import { entityTypeNameFor } from "@shapeable/web";
import { get } from 'lodash';

export const isExplorerEntity = (entity: Entity) => {
  const entityTypeName = entityTypeNameFor(entity);
  if (['Trend', 'Topic', 'Commodity', 'SubTopic'].includes(entityTypeName)) {
    return true;
  }

  return entityTypeName === 'Page' && ['PageLayoutThemeSupportingInfo'].includes(get(entity, 'page.layout.component'));
};
