import React from 'react';
import { ThemeProvider } from "@shapeable/web";
import { useValueChainMap } from "../../hooks/use-value-chain-map";

export const TrendThemeProvider: React.FC<any> = ({ children }) => {
 const { activeTrend } = useValueChainMap();
  const accentColor = activeTrend?.color?.value;

  const themeOverrides = {
    color: {
      midBrand: accentColor,
      heading: accentColor,
      link: accentColor,
      linkHover: accentColor,
    },
  };
  return (
    <ThemeProvider overrides={themeOverrides}>
    {children}
    </ThemeProvider>
  )
}
