import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, FileAsset } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { Card, CellHeader, classNames, gtagEvent, useEntity } from '@shapeable/web';
import { kebabCase } from 'lodash';
const cls = classNames('file-asset-card');

// -------- Types -------->

export type FileAssetCardProps = Classable & HasChildren & { 
  entity?: FileAsset;
  label?: string;
};

export const FileAssetCardDefaultProps: Omit<FileAssetCardProps, 'entity'> = {
  label: 'File',
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->
// min-height: 100px;
const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.UNIT(5)};
    flex-grow: 1;
    background: var(--shapeable-white, #FFF);
    min-height: 300px;
    overflow: hidden;
  `
});

const HeaderStyles = breakpoints({
  base: css`
    line-height: 1.25em;
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const NameStyles = breakpoints({
  base: css`
    text-decoration: none;
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    font-size: ${theme.FONT_SIZE(18)};
    font-weight: 500;
    line-height: 1.25em;
    cursor: pointer;
    color: ${theme.COLOR('dark')};
    &:hover {
      color: ${theme.COLOR('link-hover')};
    }
  `,
});



const FileTypeStyles = breakpoints({
  base: css`
    
  `,
});

const FileSizeStyles = breakpoints({
  base: css`
    
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Header: styled(CellHeader)`${HeaderStyles}`,
    DownloadLink: styled.a`${NameStyles}`,
      Title: styled.span`${TitleStyles}`,
    FileType: styled.span`${FileTypeStyles}`,
    FileSize: styled.span`${FileSizeStyles}`,
    
};

export const FileAssetCard: Shapeable.FC<FileAssetCardProps> = (props) => {
  const { className, children, label } = props;
  const entity = useEntity(props.entity);

  const { name, file, description, type } = entity;
  const { extension, formattedSize, typeName } = file;

  const title = <My.Title>{name}</My.Title>;

  const url = file.url || "/pdf";
  const downloadUrl = url.replace(/upload\//, 'upload/fl_attachment/');
  const onClick = () => {
    gtagEvent('page_view', { page_path: url });
  };

  const fileKebabCase = kebabCase(name)
  const result = `${fileKebabCase}.pdf`;
  
  return (
   <My.Container className={cls.name(className)}>
      <My.Header>{label}</My.Header>
      <My.DownloadLink href={downloadUrl || "/pdf"} download={result} onClick={onClick}>{title}</My.DownloadLink>
      <My.FileType>File Type: {typeName}</My.FileType>
      <My.FileSize>File Size: {formattedSize}</My.FileSize>
   {children}
   </My.Container>
  )
};

FileAssetCard.defaultProps = FileAssetCardDefaultProps;
FileAssetCard.cls = cls;