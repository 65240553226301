import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useView, EntityFilters } from '@shapeable/web';
import { useInnovations } from '../../hooks/use-innovations';
import { EntityThumbnailCardGrid } from './entity-thumbnail-card-grid';
import { InnovationIcon } from '../elements/innovation-icon';
const cls = classNames('explorer-innovations-layout');
import { linkedMatchesFilter } from '../../utils/linked-matches-filter';
import { compact } from 'lodash';
import { useInnovationsFilters } from '../../hooks/use-innovations-filters';
import { ExplorerView } from './explorer-tabs';

// -------- Types -------->

export type ExplorerInnovationsLayoutProps = Classable & HasChildren & {
}

export const ExplorerInnovationsLayoutDefaultProps: ExplorerInnovationsLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const FiltersStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} ${theme.UNIT(2)} 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} ${theme.UNIT(5)} 0;
  `,
});


const GridStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`,
    Grid: styled(EntityThumbnailCardGrid)`${GridStyles}`,
};

export const ExplorerInnovationsLayout: Shapeable.FC<ExplorerInnovationsLayoutProps> = (props) => {
  const { className } = props;
  
  const view = 'explorer';
  const { filters } = useView<ExplorerView>(view);

  const { themes = [], technologies = [], catalysts = [], horizonTypes = [] } = filters;
  const { selects } = useInnovationsFilters();
  const allInnovations = useInnovations();

  // TODO - hook this up to a proper query if the dataset becomes large
  const innovations = allInnovations.filter(innovation => (
    linkedMatchesFilter(innovation.trends, themes) &&
    linkedMatchesFilter(innovation.technologies, technologies) &&
    linkedMatchesFilter(innovation.catalysts, catalysts) &&
    linkedMatchesFilter(compact(innovation.horizons?.map(h => h.type)), horizonTypes)
  ));

  return (
    <My.Container className={cls.name(className)}>
      <My.Filters view={view} selects={selects} results={allInnovations} />
      <My.Grid items={innovations} cardProps={{ Innovation: { iconComponent: InnovationIcon }}} />
    </My.Container>
  )
};

ExplorerInnovationsLayout.cls = cls;
ExplorerInnovationsLayout.defaultProps = ExplorerInnovationsLayoutDefaultProps;