import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, useLang, useView } from '@shapeable/web';
import { TabSpec, Tabs } from '../elements/tabs';
// import { ExplorerView } from '../../hooks/use-explorer';
const cls = classNames('entity-explorer-menu');

// -------- Types -------->

export type ExplorerView = 'all' | 'value-chain' | 'commodities' | 'impact-strategies' | 'community' | 'innovations' | 'stakeholder-map' | 'library';
export type ExplorerTabsProps = Classable & HasChildren & { 
  entity?: Entity;
  activeTab?: string;
};

export const ExplorerTabsDefaultProps: Omit<ExplorerTabsProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  
  `,
  desktop: css`
  `,
});

const TabsStyles = breakpoints({
  base: css`
    padding: 0;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(Tabs)`${TabsStyles}`,
};

export const ExplorerTabs: Shapeable.FC<ExplorerTabsProps> = (props) => {
  const { className } = props;
  const { setActiveView, activeView } = useView('explorer', 'all');

  const tabs: TabSpec<ExplorerView>[] = [
    {
      id: 'all',
      label: 'All',
    },
    {
      id: 'value-chain',
      label: 'Value Chain',
    },
    {
      id: 'commodities',
      label: 'Commodities',
    },
    {
      id: 'innovations',
      label: 'Innovations',
    },
    {
      id: 'impact-strategies',
      label: 'Impact Strategies',
    },
    {
      id: 'community',
      label: 'Community',
    },
    {
      id: 'stakeholder-map',
      label: 'Stakeholder Map',
    },
    {
      id: 'library',
      label: 'Library',
    },
  ];
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Tabs onTabClick={setActiveView} activeTab={activeView} tabs={tabs} />
    </My.Container>
  )
};

ExplorerTabs.defaultProps = ExplorerTabsDefaultProps;
ExplorerTabs.cls = cls;