import { useOrganisationTypes } from '@shapeable/web';
import { useEconomies } from '@shapeable/web';
import { useTechnologies } from './use-technologies';
import { Organisation } from '@shapeable/types';
import { compact } from 'lodash';

export type CommunityFiltersVariant = 'explorer-community' | 'innovation-community';
export const useCommunityFilters = (page?: CommunityFiltersVariant ) => {
  const allTechnologies = useTechnologies();
  const allOrganisationTypes = useOrganisationTypes();
  const allEconomies = useEconomies();

  return {
    selects: compact([
      page === 'explorer-community' && {
        name: 'countries',
        items: allEconomies,
        pluralLabel: 'Countries',
      },
      page === 'innovation-community' && {
        name: 'technologies',
        items: allTechnologies,
        pluralLabel: 'Technologies',
      },
      {
        name: 'types',
        items: allOrganisationTypes,
        pluralLabel: 'Stakeholder Types',
        getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
      },
    ]),
    typeToggles: [
      { name: 'Organisation' },
      { name: 'Person' },
    ]
  };
};