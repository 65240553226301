import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteFooterLayout, SliceLayout, classNames, useEntity, useView } from '@shapeable/web';

import { ExplorerCommodityGroupsLayout } from './explorer-commodity-groups-layout';
import { ExplorerSummaryLayout } from './explorer-summary-layout';
import { ExplorerThemesLayout } from './explorer-themes-layout';
import { ExplorerInnovationsLayout } from './explorer-innovations-layout';
import { ExplorerTabs, ExplorerView } from './explorer-tabs';
import { ExplorerCommunityLayout } from './explorer-community-layout';
import { ExplorerMarketMapLayout } from './explorer-market-map-layout';
import { ExplorerLibraryLayout } from './explorer-library-layout';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import { WITH_AUTHENTICATION_REQUIRED_OPTIONS } from '../../data';
import { ExplorerImpactStrategies } from './explorer-impact-strategies';
const cls = classNames('explorer-layout');

// -------- Types -------->

export type ExplorerLayoutProps = Classable & HasChildren & { 
  entity: Entity;
};

export const ExplorerLayoutDefaultProps: Omit<ExplorerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  `,
});

const TabsStyles = breakpoints({
  base: css`
    
  `,
});

const ViewChildStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});



const CommodityGroupsStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const InnovationsStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const SummaryStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ThemesStyles = breakpoints({
  base: css`
    ${ViewChildStyles};
  `,
});

const ViewStyles = breakpoints({
  base: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--shapeable-warm-grey, #ECEDE8);
  `,
});

const FooterLayoutStyles = breakpoints({
  base: css`
    
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Tabs: styled(ExplorerTabs)`${TabsStyles}`,
    View: styled.div`${ViewStyles}`,
      Summary: styled(ExplorerSummaryLayout).attrs(cls.attr('summary'))`${SummaryStyles}`,
      Commodities: styled(ExplorerCommodityGroupsLayout).attrs(cls.attr('commodities'))`${CommodityGroupsStyles}`,
      Innovations: styled(ExplorerInnovationsLayout).attrs(cls.attr('innovations'))`${InnovationsStyles}`,
      ValueChain: styled(ExplorerThemesLayout).attrs(cls.attr('value-chain'))`${ThemesStyles}`,
      ImpactStrategies: styled(ExplorerImpactStrategies).attrs(cls.attr('impact-strategies'))`${ViewChildStyles}`,
      Community: styled(ExplorerCommunityLayout).attrs(cls.attr('community'))`${ViewChildStyles}`,
      MarketMap: styled(ExplorerMarketMapLayout).attrs(cls.attr('market-map'))`${ViewChildStyles}`,
      Library: styled(ExplorerLibraryLayout).attrs(cls.attr('library'))`${ViewChildStyles}`,

      Footer: styled(SiteFooterLayout)`${FooterLayoutStyles}`,

};

export const ExplorerLayoutComponent: Shapeable.FC<ExplorerLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { activeView } = useView<ExplorerView>('explorer', 'all');

  const auth = useAuth0();
  const { logout, isAuthenticated, isLoading, error, loginWithRedirect, user } = auth;

  const { id, } = user;
  
  return (
    <>
    <SliceLayout boundary='feature'> 
    <My.Container className={cls.name(className)}>
      <My.Tabs />
      <My.View>
      {
        activeView === 'all' && 
        <My.Summary entity={entity} />
      }
      {
        activeView === 'commodities' && 
        <My.Commodities />
      }
      {
        activeView === 'innovations' && 
        <My.Innovations />
      }
      {
        activeView === 'value-chain' && 
        <My.ValueChain />
      }
      {
        activeView === 'impact-strategies' &&
        <My.ImpactStrategies />
      }
      {
        activeView === 'community' &&
        <My.Community />
      }
      {
        activeView === 'stakeholder-map' &&
        <My.MarketMap />
      }
      {
        activeView === 'library' &&
        <My.Library />
      }

      </My.View>
    </My.Container>
    </SliceLayout>
    <My.Footer backgroundColor='#FFFFFF' />
    </>
  )
};

ExplorerLayoutComponent.defaultProps = ExplorerLayoutDefaultProps;
ExplorerLayoutComponent.cls = cls;

export const ExplorerLayout = withAuthenticationRequired(ExplorerLayoutComponent, WITH_AUTHENTICATION_REQUIRED_OPTIONS);