import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityCardGrid, EntityFilters, classNames, WaitMessage, useView } from '@shapeable/web';
import { useQuery, gql } from '@apollo/client';
const cls = classNames('explorer-library-layout');

// -------- Types -------->

export type ExplorerLibraryLayoutProps = Classable & HasChildren & {
}

export const ExplorerLibraryLayoutDefaultProps: ExplorerLibraryLayoutProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--card__body {
      background: var(--shapeable-white, #FFF);
    }
  `,
  desktop: css`
    padding: ${theme.UNIT(4)};
  `
});

const WaitMessageStyles = breakpoints({
  base: css`
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const FiltersStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});




// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Filters: styled(EntityFilters)`${FiltersStyles}`, 
    WaitMessage: styled(WaitMessage)`${WaitMessageStyles}`,
    Grid: styled(EntityCardGrid)`${GridStyles}`,
};


export const ExplorerLibraryLayout: Shapeable.FC<ExplorerLibraryLayoutProps> = (props) => {
  const { className } = props;
  const { resultsFor } = useView('explorer', 'all');

  const { loading, data } = useQuery(
    gql`
      query { 
        allFileAssets {
          id name slug file { id extension formattedSize url typeName }
        }
        allCitations {
          __typename _schema { label pluralLabel }
          id path slug url name
          authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
        }
        allPosts {
          id path name slug
          openGraph { id image { id url } }
        }
      }
  `);

  const library = [
    ...resultsFor({ type: 'Post', data: data?.allPosts, by: 'name' }),
    ...resultsFor({ type: 'Citation', data: data?.allCitations, by: 'name' }),
    ...resultsFor({ type: 'FileAsset', data: data?.allFileAssets, by: 'name' }),
  ];

  return (
    <My.Container className={cls.name(className)}>
      <My.Filters 
        view="explorer"
        typeToggles={[
          { name: "Post", label: "Articles" },
          { name: "Citation", label: "Scientific Papers" },
          { name: "FileAsset", label: 'Files' },
        ]}
      />
      {
        loading ? 
        <My.WaitMessage /> : 
        <My.Grid items={library} desktopAutoColumns={4} spacing={2} />
      }
    </My.Container>
  )
};

ExplorerLibraryLayout.cls = cls;
ExplorerLibraryLayout.defaultProps = ExplorerLibraryLayoutDefaultProps;