import { MineralProcessingIcon } from "../components/icons/mineral-processing-icon";
import { COLOR_VALUE_MINERAL_PROCESSING } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendMineralProcessing: TrendNodeSpec = {
  width: 159,
  height: 170,
  x: 534.5,
  y: 519,
  slug: 'mineral-processing',
  color: COLOR_VALUE_MINERAL_PROCESSING,
  icon: {
    x: 58.5, y: 29, width: 41, height: 43,
    component: MineralProcessingIcon,
  },
  bubble: {
    text: [
      { x: 41.8379, y: 134.75, text: 'Mineral' },
      { x: 24.6826, y: 163.75, text: 'Processing' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 612.661, y: 569.028,
    text: [
      { x: 631, y: 594.25, text: 'Mineral' },
      { x: 631, y: 612.25, text: 'Processing' },
    ],
  },
  topicNodes: [
    // {
    //   slug: 'slug',
    //   text: [ 
    //     { x: 0, y: 0, text: 'TEXT' },
    //   ],
    //   bubble: { x: 0, y: 0 },
    //   path: "PATH",
    // },
  ],
};
