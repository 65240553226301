import { TrendLineSpec, TrendNodeSpec } from "./types";
import { trendMaterialMovement } from "./trend-material-movement";
import { trendMineDevelopment } from "./trend-mine-development";
import { trendDiscovery } from "./trend-discovery";
import { trendEnergy } from "./trend-energy";
import { trendAir } from "./trend-air";
import { trendWater } from "./trend-water";
import { trendCircularEconomy } from "./trend-circular-economy";
import { trendMineralProcessing } from "./trend-mineral-processing";

export const valueChainMapTrends: TrendNodeSpec[] = [
  trendDiscovery,
  trendMineDevelopment,
  trendMaterialMovement,
  trendMineralProcessing,
  trendCircularEconomy,
  trendWater,
  trendAir,
  trendEnergy,
];

export const valueChainMapTrendLines: TrendLineSpec[] = [
  { id: "line-3-4", d: "M397.95 48.6797C397.95 48.6797 305.81 115.93 182.38 138.37" },
  { id: "line-3-5", d: "M397.95 48.6797C397.95 48.6797 294.34 237.33 93.03 354.16" },
  { id: "line-3-6", d: "M397.95 48.6797C397.95 48.6797 360.65 309.42 182.45 569.47" },
  { id: "line-2-3", d: "M397.95 48.6797C397.95 48.6797 489.55 116.54 612.97 138.99" },
  { id: "line-1-3", d: "M397.95 48.6797C397.95 48.6797 501.49 237.2 702.8 354.02" },
  { id: "line-3-8", d: "M397.95 48.6797C397.95 48.6797 435.11 309.32 613.32 569.37" },
  { id: "line-4-5", d: "M182.38 138.38C182.38 138.38 164.44 251.02 93.03 354.17" },
  { id: "line-4-6", d: "M182.38 138.38C182.38 138.38 242.19 344.52 182.45 569.48" },
  { id: "line-4-7", d: "M182.38 138.38C182.38 138.38 340.15 349.25 398.02 659.15" },
  { id: "line-2-4", d: "M182.38 138.38C182.38 138.38 388.02 198.73 612.97 138.99" },
  { id: "line-1-4", d: "M182.38 138.38C182.38 138.38 392.9 296.16 702.8 354.03" },
  { id: "line-5-7", d: "M93.04 354.16C93.04 354.16 281.2 457.83 398.03 659.14" },
  { id: "line-5-8", d: "M93.04 354.16C93.04 354.16 353.27 391.16 613.33 569.37" },
  { id: "line-2-5", d: "M93.04 354.159C93.04 354.159 352.93 317.199 612.98 138.989" },
  { id: "line-6-8", d: "M182.45 569.47C182.45 569.47 388.37 509.63 613.33 569.37" },
  { id: "line-1-6", d: "M182.45 569.47C182.45 569.47 392.9 411.89 702.8 354.02" },
  { id: "line-5-6", d: "M182.01 569.91C182.01 569.91 166.14 456.07 94.74 352.93" },
  { id: "line-7-8", d: "M398.02 659.14C398.02 659.14 489.9 591.81 613.32 569.37" },
  { id: "line-7-1", d: "M398.02 659.14C398.02 659.14 501.49 470.85 702.8 354.02" },
  { id: "line-2-7", d: "M398.02 659.139C398.02 659.139 434.76 399.039 612.97 138.989" },
  { id: "line-6-7", d: "M398.02 659.14C398.02 659.14 305.87 591.91 182.45 569.47" },
  { id: "line-8-2", d: "M613.32 569.369C613.32 569.369 553.23 363.939 612.97 138.989" },
  { id: "line-1-2", d: "M702.8 354.029C702.8 354.029 635.42 262.419 612.98 138.989" },
  { id: "line-8-1", d: "M702.8 354.029C702.8 354.029 635.77 445.949 613.33 569.379" },
  { id: "line-2-6", d: "M182.45 569.469L612.98 138.989" },
  { id: "line-4-8", d: "M613.32 569.37L182.38 138.38" },
  { id: "line-3-7", d: "M397.95 48.6797L398.02 659.14" },
  { id: "line-1-5", d: "M702.8 354.029L93.04 354.159" },
];

