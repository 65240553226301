import { Dictionary, Page } from "@shapeable/types";
import { Deposit } from '@shapeable/collaborate-ore-types';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';

export const METALLOGENICA_PORTAL_DEPOSIT_URL="https://dmgeode.com/DepositPortal.aspx?DepositID=";

export const PAGE_EXPLORER: Page = {
  id: 'explorer',
  slug: 'explorer',
  path: '/explorer',
};

export const WITH_AUTHENTICATION_REQUIRED_OPTIONS: WithAuthenticationRequiredOptions = {
  loginOptions: {
    appState: { targetUrl: '/explorer' },
  }
};

export const ENTITY_OPEN_GRAPH_FIELDS = `openGraph { id title description { id text } image { id url } }`;
export const ENTITY_SCHEMA_FIELDS = `_entityTypeName _schema { label pluralLabel }`;
