import { WaterIcon } from "../components/icons/water-icon";
import { COLOR_VALUE_WATER } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendWater: TrendNodeSpec = {
  width: 158,
  height: 142,
  x: 104,
  y: 518,
  slug: 'water',
  color: COLOR_VALUE_WATER,
  icon: {
    x: 57.28, y: 26, width: 43, height: 45,
    component: WaterIcon,
  },
  bubble: {
    text: [
      { x: 47.1838, y: 135.805, text: 'Water' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 183.26, y: 569.03,
    text: [
      { x: 132.459, y: 594.25, text: 'Water' },
    ],
  },
  topicNodes: [

    {
      slug: 'water-stress',
      text: [
        { x: 162.51, y: 348.271, text: 'Water' },
        { x: 160.65, y: 363.271, text: 'Stress' },
      ],
      bubble: { x: 228.055, y: 351 },
      path: "M181.25 564.289C181.25 564.289 235.88 455.629 227.72 352.099",
    },

    {
      slug: 'site-wide-water-balance',
      text: [
        { x: 191.19, y: 219.601, text: 'Site Wide' },
        { x: 215.22, y: 234.601, text: 'Water' },
        { x: 201.29, y: 249.601, text: 'Balance' },
      ],
      bubble: { x: 279.63, y: 231.679 },
      path: "M181.43 564.839C181.43 564.839 311.02 398.529 279.52 231.679",
    },

    {
      slug: 'operational-water-use',
      text: [
        { x: 594.87, y: 343.32, text: 'Operational' },
        { x: 594.87, y: 358.32, text: 'Water' },
        { x: 594.87, y: 373.32, text: 'Use' },
      ],
      bubble: { x: 570.09, y: 351 },
      path: "M185.47 569.809C185.47 569.809 410.43 366.789 568.87 351.619",
    },

    {
      slug: 'dewatering',
      text: [
        { x: 361.77, y: 157.271, text: 'Dewatering' },
      ],
      bubble: { x: 399.92, y: 183.149 },
      path: "M182.21 566.549C182.21 566.549 385.23 341.589 400.4 183.149",
    },

    {
      slug: 'desalination',
      text: [
        { x: 536.43, y: 217.581, text: 'Desalination' },
      ],
      bubble: { x: 519.5, y: 231.679 },
      path: "M184.78 567.249L519.18 233.319",
    },

    {
      slug: 'tailings',
      text: [
        { x: 541.43, y: 494.801, text: 'Tailings' },
      ],
      bubble: { x: 521.83, y: 473.194 },
      path: "M187.18 570.589C187.18 570.589 353.49 440.999 520.34 472.499",
    },

    {
      slug: 'end-use-and-reuse',
      text: [
        { x: 414.25, y: 552.221, text: 'End Use' },
        { x: 414.25, y: 567.221, text: 'and Reuse' },
      ],
      bubble: { x: 399.92, y: 526.18 },
      path: "M187.73 570.769C187.73 570.769 296.39 516.139 399.92 524.299",
    },

  ],
};
