import React from 'react';
import styled, { css } from 'styled-components';
const cls = classNames('citation-card');
import { Classable, HasChildren, Citation, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, CitationCell, EntityTextValue, EntityTextValueList, useEntity, useLang, EntityCard, EntityCardProps } from '@shapeable/web';

// -------- Types -------->

export type ExplorerCitationCardProps = Omit<EntityCardProps, 'entity'> & { 
  entity?: Citation;
  label?: string;
  hasBorder?: boolean;
};

export const ExplorerCitationCardDefaultProps: Omit<ExplorerCitationCardProps, 'entity'> = {
  label: "Scientific Paper",
  hasBorder: false,
};

// -------- Child Component Props -------->

type ContainerProps = {
  hasBorder?: boolean;
}

// -------- Styles -------->

const CardStyles = breakpoints({
  base: css`
    .shp--card__content {
    padding: ${theme.UNIT(5)};
    background: #FFF;
    line-height: 1.8em;
    margin: ${theme.UNIT(0)};

    .shp--citation-cell__number {
      display: none;
    }

    img {
      object-fit: contain;
    }

    a {
      text-decoration: none;
      color: ${theme.COLOR('link')};

      &:hover {
        color: ${theme.COLOR('link-hover')};
      }
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

  }
  `,
  desktop: css`

    
  `
});

const LinkedStyles = breakpoints({
  base: css`
    
  `,
});

const LinkedListStyles = breakpoints({
  base: css`
    
  `,
});

const CellStyles = breakpoints({
  base: css`
    a {
      word-break: break-all; 
    }
  `,
});




// -------- Components -------->

const My = {
  Card: styled(EntityCard)<ContainerProps>`${CardStyles}`,
    Linked: styled(EntityTextValue)`${LinkedStyles}`,
    LinkedList: styled(EntityTextValueList)`${LinkedListStyles}`,

    Cell: styled(CitationCell)`${CellStyles}`,

};

export const ExplorerCitationCard: Shapeable.FC<ExplorerCitationCardProps> = (props) => {
  const { className, children, label, hasBorder, ...rest } = props;
  const entity = useEntity(props.entity);
  const t = useLang();

  return (
    <My.Card {...rest} spaceUnits={0} href={entity.url || ""} hasBorder={hasBorder} target="_blank" label={label} className={cls.name(className)}>
      <My.Cell entity={entity} isExtended />
      {children}
    </My.Card>
  )
};

ExplorerCitationCard.defaultProps = ExplorerCitationCardDefaultProps;
ExplorerCitationCard.cls = cls;