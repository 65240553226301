import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, CitationCard as Card } from '@shapeable/web';
const cls = classNames('citation-card');

// -------- Types -------->

export type CitationCardProps = Classable & HasChildren & { 
  entity?: Entity;
};

export const CitationCardDefaultProps: Omit<CitationCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    .shp--card__content {
      padding: ${theme.UNIT(5)};
      border: 2px dotted ${theme.COLOR('line-mid')};
      background: #FFF;
      border-radius: 0 40px 0 0;
      line-height: 1.3em;

      a {
        text-decoration: none;
        color: ${theme.COLOR('link')};

        &:hover {
          color: ${theme.COLOR('link-hover')};
        }
      }

      
      > .shp--cell-header > .shp--cell-header__label-and-badge > .shp--cell-label {
        line-height: 1.8em;
      }

      .shp--more-divider {
        margin-top: 0;
      }
    }

  `,
});


// -------- Components -------->

const My = {
  Container: styled(Card)<ContainerProps>`${ContainerStyles}`,
};

export const CitationCard: Shapeable.FC<CitationCardProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
   <My.Container label="Research Paper" entity={entity} className={cls.name(className)}>
   {children}
   </My.Container>
  )
};

CitationCard.defaultProps = CitationCardDefaultProps;
CitationCard.cls = cls;