import { MaterialMovementIcon } from "../components/icons/material-movement-icon";
import { COLOR_VALUE_MATERIAL_MOVEMENT } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendMaterialMovement: TrendNodeSpec = {
  width: 159,
  height: 170,
  x: 620.5,
  y: 303,
  slug: 'material-movement',
  color: COLOR_VALUE_MATERIAL_MOVEMENT,
  icon: {
    x: 57.28, y: 36, width: 44, height: 31,
    component: MaterialMovementIcon,
  },
  bubble: {
    text: [
      { x: 38.1533, y: 134.75, text: 'Material' },
      { x: 26.5518, y: 163.75, text: 'Movement' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 701.85, y: 354.33,
    text: [
      { x: 727, y: 350.25, text: 'Material' },
      { x: 727, y: 368.25, text: 'Movement' },
    ],
  },
  topicNodes: [
    {
      slug: 'renewable-energy-adoption',
      text: [ 
        { x: 547.055, y: 486.74, text: 'Renewable' },
        { x: 547.055, y: 501.74, text: 'energy' },
        { x: 547.055, y: 516.74, text: 'adoption' },
      ],
      path: "M702.571 356.667C702.571 356.667 581.291 363.177 530.691 462.547",
      bubble: { x: 531.115, y: 463.325 },
    },

    {
      slug: 'fragmentation',
      text: [ 
        { x: 381.43, y: 554.242, text: 'Fragmentation' },
      ],
      path: "M702.571 357.667C702.571 357.667 529.171 352.367 429.751 520.677",
      bubble: { x: 428.896, y: 521.234 },
    },

    {
      slug: 'convey-and-sort',
      text: [ 
        { x: 245.103, y: 522.74, text: 'Convey' },
        { x: 237.104, y: 537.74, text: 'and Sort' },
      ],
      path: "M702.571 356.667C702.571 356.667 455.331 351.537 315.071 500.457",
      bubble: { x: 314.277, y: 500.615 },
    },

    {
      slug: 'haulage',
      text: [ 
        { x: 160.375, y: 423.45, text: 'Haulage' },
      ],
      path: "M702.571 356.667C702.571 356.667 471.421 341.337 239.171 411.357",
      bubble: { x: 239.025, y: 411.325 },
    },

    {
      slug: 'dispose-and-reuse',
      text: [ 
        { x: 165.565, y: 271.595, text: 'Dispose' },
        { x: 148.529, y: 286.595, text: 'and Reuse' },
      ],
      path: "M702.571 348.827C702.571 348.827 471.421 368.157 239.171 295.137",
      bubble: { x: 239.025, y: 294.544 },
    },

    {
      slug: 'digital-and-automation',
      text: [ 
        { x: 227.698, y: 175.942, text: 'Digital and' },
        { x: 220.726, y: 190.942, text: 'Automation' },
      ],
      path: "M702.571 349.827C702.571 349.827 455.331 354.957 315.071 206.037",
      bubble: { x: 314.277, y: 204.395 },
    },

    {
      slug: 'electrification',
      text: [ 
        { x: 381.095, y: 163.829, text: 'Electrification' },
      ],
      path: "M702.571 348.827C702.571 348.827 529.171 354.127 429.751 185.817",
      bubble: { x: 429.93, y: 185.117 },
    },

    {
      slug: 'hydrogen',
      text: [ 
        { x: 545.115, y: 225.02, text: 'Hydrogen' },
      ],
      path: "M702.571 349.827C702.571 349.827 581.291 343.317 530.691 243.947",
      bubble: { x: 530.115, y: 243.47 },
    },

  ]

};
