import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, LogoCard, useLink } from '@shapeable/web';
import { Organisation } from '@shapeable/collaborate-ore-types';
const cls = classNames('market-map-grid');

// -------- Types -------->

export type MarketMapGridProps = Classable & HasChildren & { 
  items: Organisation[];
};

export const MarketMapGridDefaultProps: MarketMapGridProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-wrap: wrap;
    padding: 0 16px 16px 16px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
    justify-content: flex-start;

  `
});

const ItemStyles = breakpoints({
  base: css`
    flex-grow: 1;
    background: ${theme.COLOR('light')};
    a {
      text-decoration: none;
    }
    
    img {
      object-fit: contain !important;
    }
  `,
});

const CardStyles = breakpoints({
  base: css`
    
  `,
});


const FauxLogoStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('dark')};
    text-decoration: none;
    align-items: center;
    justify-content: center;
    display: flex;
    text-transform: capitalize;
    font-family: ${theme.FONT('sans')};
    font-size: ${theme.FONT_SIZE(12)};
    padding: ${theme.UNIT(4)} ${theme.UNIT(5)};
    font-weight: 600;
    text-align: center;
    line-height: 1.1em;
    height: 82px;
    min-width: 82px;
    max-width: 150px;
    
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Item: styled.div`${ItemStyles}`,
    Card: styled(LogoCard)`${CardStyles}`,
    FauxLogo: styled.div`${FauxLogoStyles}`,
};

export const MarketMapGrid: Shapeable.FC<MarketMapGridProps> = (props) => {
  const { className, children, items } = props;
  
  const { Link } = useLink();

  return (
    <My.Container className={cls.name(className)}>
    {
      items.map(organisation => {
        const { logo } = organisation;
        return (
          <My.Item>
          {
          !!logo?.url ?
          <My.Card key={organisation.id} entity={organisation} /> : 
          (
            organisation.url ? 
            <Link href={organisation.url} target="_blank">
              <My.FauxLogo key={organisation.id}>{organisation.name}</My.FauxLogo>
            </Link> :
            <My.FauxLogo key={organisation.id}>{organisation.name}</My.FauxLogo>
          )
          }
          </My.Item>
        )
      })
    }
    {children}
    </My.Container>
  )
};

MarketMapGrid.defaultProps = MarketMapGridDefaultProps;
MarketMapGrid.cls = cls;