import React from 'react';
import { IconComponent } from '@shapeable/types'

export const InnovationIcon: IconComponent = ({ className }) => (
  <svg className={className} width="48" height="48" viewBox="0 0 48 48">
    <g clip-path="url(#clip0_17_1602)">
      <path d="M24 4.17391C34.9565 4.17391 43.8261 13.0435 43.8261 24C43.8261 34.9565 34.9565 43.8261 24 43.8261C13.0435 43.8261 4.17391 34.9565 4.17391 24C4.17391 13.0435 13.0435 4.17391 24 4.17391ZM24 0C10.7478 0 0 10.7478 0 24C0 37.2522 10.7478 48 24 48C37.2522 48 48 37.2522 48 24C48 10.7478 37.2522 0 24 0Z" fill="black"/>
      <path d="M24.0001 19.8261C21.7044 19.8261 19.8262 21.7043 19.8262 24C19.8262 26.2956 21.7044 28.1739 24.0001 28.1739C26.2957 28.1739 28.174 26.2956 28.174 24C28.174 21.7043 26.2957 19.8261 24.0001 19.8261Z" fill="black"/>
      <path d="M24 13.5652C22.8522 13.5652 21.9131 14.5043 21.9131 15.6522C21.9131 16.8 22.8522 17.7391 24 17.7391C25.1479 17.7391 26.087 16.8 26.087 15.6522C26.087 14.5043 25.1479 13.5652 24 13.5652Z" fill="black"/>
      <path d="M24 30.2609C22.8522 30.2609 21.9131 31.2 21.9131 32.3478C21.9131 33.4956 22.8522 34.4348 24 34.4348C25.1479 34.4348 26.087 33.4956 26.087 32.3478C26.087 31.2 25.1479 30.2609 24 30.2609Z" fill="black"/>
      <path d="M24 9.3913C23.3739 9.3913 22.9565 9.80869 22.9565 10.4348C22.9565 11.0609 23.3739 11.4783 24 11.4783C24.6261 11.4783 25.0435 11.0609 25.0435 10.4348C25.0435 9.80869 24.6261 9.3913 24 9.3913Z" fill="black"/>
      <path d="M24 36.5217C23.3739 36.5217 22.9565 36.9391 22.9565 37.5652C22.9565 38.1913 23.3739 38.6087 24 38.6087C24.6261 38.6087 25.0435 38.1913 25.0435 37.5652C25.0435 36.9391 24.6261 36.5217 24 36.5217Z" fill="black"/>
      <path d="M16.5914 16.5913C15.7566 17.4261 15.7566 18.6783 16.5914 19.513C17.4262 20.3478 18.6784 20.3478 19.5132 19.513C20.3479 18.6783 20.3479 17.4261 19.5132 16.5913C18.7827 15.7565 17.4262 15.7565 16.5914 16.5913Z" fill="black"/>
      <path d="M28.3827 28.3826C27.5479 29.2174 27.5479 30.4696 28.3827 31.3043C29.2175 32.1391 30.4696 32.1391 31.3044 31.3043C32.1392 30.4696 32.1392 29.2174 31.3044 28.3826C30.574 27.6522 29.2175 27.6522 28.3827 28.3826Z" fill="black"/>
      <path d="M13.6695 13.6695C13.2521 14.0869 13.2521 14.713 13.6695 15.1304C14.0869 15.5478 14.713 15.5478 15.1304 15.1304C15.5478 14.713 15.5478 14.0869 15.1304 13.6695C14.713 13.2522 14.0869 13.2522 13.6695 13.6695Z" fill="black"/>
      <path d="M32.8697 32.8696C32.4523 33.287 32.4523 33.913 32.8697 34.3304C33.2871 34.7478 33.9132 34.7478 34.3306 34.3304C34.7479 33.913 34.7479 33.287 34.3306 32.8696C33.9132 32.4522 33.2871 32.4522 32.8697 32.8696Z" fill="black"/>
      <path d="M13.5652 24C13.5652 25.1478 14.5043 26.087 15.6521 26.087C16.8 26.087 17.7391 25.1478 17.7391 24C17.7391 22.8522 16.8 21.9131 15.6521 21.9131C14.5043 21.9131 13.5652 22.8522 13.5652 24Z" fill="black"/>
      <path d="M30.261 24C30.261 25.1478 31.2001 26.087 32.3479 26.087C33.4958 26.087 34.4349 25.1478 34.4349 24C34.4349 22.8522 33.4958 21.9131 32.3479 21.9131C31.2001 21.9131 30.261 22.8522 30.261 24Z" fill="black"/>
      <path d="M9.39136 24C9.39136 24.6261 9.80875 25.0435 10.4348 25.0435C11.0609 25.0435 11.4783 24.6261 11.4783 24C11.4783 23.3739 11.0609 22.9565 10.4348 22.9565C9.80875 22.9565 9.39136 23.3739 9.39136 24Z" fill="black"/>
      <path d="M36.5217 24C36.5217 24.6261 36.9391 25.0435 37.5652 25.0435C38.1913 25.0435 38.6087 24.6261 38.6087 24C38.6087 23.3739 38.1913 22.9565 37.5652 22.9565C36.9391 22.9565 36.5217 23.3739 36.5217 24Z" fill="black"/>
      <path d="M16.5914 31.4087C17.4262 32.2435 18.6784 32.2435 19.5132 31.4087C20.3479 30.5739 20.3479 29.3217 19.5132 28.487C18.6784 27.6522 17.4262 27.6522 16.5914 28.487C15.7566 29.2174 15.7566 30.5739 16.5914 31.4087Z" fill="black"/>
      <path d="M28.3825 19.6174C29.2173 20.4522 30.4695 20.4522 31.3043 19.6174C32.1391 18.7826 32.1391 17.5305 31.3043 16.6957C30.4695 15.8609 29.2173 15.8609 28.3825 16.6957C27.6521 17.4261 27.6521 18.7826 28.3825 19.6174Z" fill="black"/>
      <path d="M13.6695 34.3304C14.0869 34.7478 14.713 34.7478 15.1304 34.3304C15.5478 33.913 15.5478 33.287 15.1304 32.8696C14.713 32.4522 14.0869 32.4522 13.6695 32.8696C13.2521 33.287 13.2521 33.913 13.6695 34.3304Z" fill="black"/>
      <path d="M32.8697 15.1304C33.2871 15.5478 33.9132 15.5478 34.3306 15.1304C34.7479 14.713 34.7479 14.0869 34.3306 13.6695C33.9132 13.2522 33.2871 13.2522 32.8697 13.6695C32.4523 14.0869 32.4523 14.713 32.8697 15.1304Z" fill="black"/>
    </g>
    <defs>
      <clipPath id="clip0_17_1602">
        <rect width="48" height="48" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);