import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { Trend } from '@shapeable/collaborate-ore-types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity } from '@shapeable/web';
import { ValueChainMap } from '../elements/value-chain-map';
const cls = classNames('trend-Aside-layout');

// -------- Types -------->

export type TrendAsideLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendAsideLayoutDefaultProps: Omit<TrendAsideLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-right: ${theme.UNIT(3)};
  `,
});

const MapStyles = breakpoints({
  base: css`
    max-width: 100%;
    margin-top: ${theme.UNIT(2)};
    height: auto;
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Map: styled(ValueChainMap)`${MapStyles}`,
};

export const TrendAsideLayout: Shapeable.FC<TrendAsideLayoutProps> = (props) => {
  const { className, children } = props;
  const entity = useEntity(props.entity);
  
  return (
    <My.Container className={cls.name(className)}>
      <My.Map />
    </My.Container>
  )
};

TrendAsideLayout.defaultProps = TrendAsideLayoutDefaultProps;
TrendAsideLayout.cls = cls;