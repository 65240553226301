import { DiscoveryIcon } from "../components/icons/discovery-icon";
import { COLOR_VALUE_DISCOVERY } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendDiscovery: TrendNodeSpec = {
  width: 158,
  height: 140,
  x: 319,
  y: 0,
  slug: 'discovery',
  color: COLOR_VALUE_DISCOVERY,
  icon: {
    x: 54.28, y: 30, width: 49, height: 44,
    component: DiscoveryIcon,
  },
  bubble: {
    text: [
      { x: 29.8652, y: 133.787, text: 'Discovery' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 397.96, y: 50.7001,
    text: [
      { x: 365.232, y: 24.25, text: 'Discovery' },
    ],
  },
  topicNodes: [
    // {
    //   slug: 'slug',
    //   text: [ 
    //     { x: 0, y: 0, text: 'TEXT' },
    //   ],
    //   bubble: { x: 0, y: 0 },
    //   path: "PATH",
    // },
  ],
};
