import { graphql, useStaticQuery } from "gatsby";
import * as React from "react"; // tslint:disable-line
import { platformProviderValue } from "@shapeable/web";
import { TechnologiesProvider as Provider } from "../../components/context/technologies-context";

const QUERY = graphql`
  query TechnologiesQuery {
    platform {
      allTechnologies {
        id name slug path __typename _entityTypeName
        openGraph { image { url } }
      }
    }
  }
`

export const TechnologiesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTechnologies' ) }>{children}</Provider>;
}