module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-HE48YGT0JN"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"35420c18fd034ef720badc40a2b205ab"},
    },{
      plugin: require('../node_modules/@shapeable/gatsby-theme-shapeable-web/gatsby-browser.js'),
      options: {"plugins":[],"entities":{"OrganisationType":{},"HorizonType":{},"Economy":{},"FeedEntry":{},"Post":{},"Citation":{}},"siteMetadata":{"title":"","description":"","image":"","video":"","twitter":"","threads":"","linkedin":"","facebook":"","flickr":"","tiktok":"","youtube":"","instagram":"","name":"","logo":"","titleTemplate":"%s - %s","openGraphTitleTemplate":"%s - %s"}},
    }]
