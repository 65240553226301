import { CircularEconomyIcon } from "../components/icons/circular-economy-icon";
import { COLOR_VALUE_CIRCULAR_ECONOMY } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendCircularEconomy: TrendNodeSpec = {
  width: 158,
  height: 172,
  x: 319,
  y: 608,
  slug: 'circular-economy',
  color: COLOR_VALUE_CIRCULAR_ECONOMY,
  icon: {
    x: 55.78, y: 29, width: 46, height: 46,
    component: CircularEconomyIcon,
  },
  bubble: {
    text: [
      { x: 40.2922, y: 136.75, text: 'Circular' },
      { x: 33.6858, y: 165.75, text: 'Economy' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 397.96, y: 657.959,
    text: [
      { x: 372.419, y: 695.25, text: 'Circular ' },
      { x: 368.215, y: 713.25, text: 'Economy' },
    ],
  },
  topicNodes: [

    {
      slug: 'consumer-context',
      text: [
        { x: 177.125, y: 482.115, text: 'Consumer' },
        { x: 193.565, y: 497.115, text: 'Context' },
      ],
      bubble: { x: 264.985, y: 463.53 },
      path: "M394.455 658.38C394.455 658.38 348.215 530.59 264.385 464.59",
    },

    {
      slug: 'industrial-context',
      text: [
        { x: 141.165, y: 316.206, text: 'Industrial' },
        { x: 155.035, y: 331.206, text: 'Context' },
      ],
      bubble: { x: 230.955, y: 319.2 },
      path: "M394.975 658.641C394.975 658.641 360.435 430.931 232.385 320.041",  
    },

    {
      slug: 'challenges-in-analysis',
      text: [
        { x: 230.955, y: 177.146, text: 'Challenges' },
        { x: 232.965, y: 192.146, text: 'in Analysis' },
      ],
      bubble: { x: 323.286, y: 202.81 },
      path: "M394.735 659.3C394.735 659.3 396.075 415.75 324.135 203.88",
    },

    {
      slug: 'circular-water-system',
      text: [
        { x: 488.825, y: 159.775, text: 'Circular' },
        { x: 488.825, y: 174.775, text: 'Water' },
        { x: 488.825, y: 189.775, text: 'System' },
      ],
      bubble: { x: 471.575, y: 201.74 },
      path: "M400.715 659.3C400.715 659.3 399.375 415.75 471.315 203.88",
    },

    {
      slug: 'circular-metal-production',
      text: [
        { x: 588.395, y: 307.295, text: 'Circular' },
        { x: 588.395, y: 322.295, text: 'Metal' },
        { x: 588.395, y: 337.295, text: 'Production' },
      ],
      bubble: { x: 564.416, y: 317.88 },
      path: "M400.475 658.641C400.475 658.641 435.015 430.931 563.065 320.041",
    },

    {
      slug: 'waste-valorisation',
      text: [
        { x: 554.415, y: 478.155, text: 'Waste' },
        { x: 554.415, y: 493.155, text: 'Valorisation' },
      ],
      bubble: { x: 532.075, y: 462.53 },
      path: "M400.995 658.38C400.995 658.38 447.235 530.59 531.065 464.59",
    },

  ],
};
