import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, HorizonTypesProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query HorizonTypesQuery {
    platform {
      allHorizonTypes {
        _optionLabel
        __typename _schema { label pluralLabel }
        id name slug value subtitle subtitle
      }
    }
  }
`
export const HorizonTypesProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allHorizonTypes' ) }>{children}</Provider>;
}
