import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, EntityTypeName, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, GlobeCircleIcon, Icons, SidebarButton, classNames, useLang, useMapState, useMapUtils } from '@shapeable/web';
import { includes, without } from 'lodash';
import { useValueChainMap } from '../../hooks/use-value-chain-map';
const cls = classNames('sidebar-buttons');

// -------- Types -------->

export type SidebarButtonsProps = Classable & HasChildren & {
}

export const SidebarButtonsDefaultProps: SidebarButtonsProps = {
};

// -------- Styles -------->

const ButtonStyles = breakpoints({
  base: css`

  `,
});

const IconStyles = breakpoints({
  base: css`
    width: 32px;
    height: 32px;
    margin-bottom: ${theme.UNIT(2)};
  `,
});



// -------- Components -------->

const My = {
  Container: React.Fragment,
    Button: styled(SidebarButton)`${ButtonStyles}`,
    Icon: styled(Icons.RadarCircle)`${IconStyles}`,

};

export const SidebarButtons: Shapeable.FC<SidebarButtonsProps> = (props) => {
  const { className } = props;
  const t = useLang();
  const valueChainMap = useValueChainMap();

  return (
    <My.Container>
      <My.Button isDisclosed={valueChainMap.isShown} onClick={() => { valueChainMap.toggle() }} icon={<My.Icon />}>{t(valueChainMap.isShown ? 'Hide Map' : 'Show Map')}</My.Button>
    </My.Container>
  )
};

SidebarButtons.cls = cls;
SidebarButtons.defaultProps = SidebarButtonsDefaultProps;