import { useEconomies } from "@shapeable/web";
import { useOrganisationTypes } from "@shapeable/web";
import { useTechnologies } from "./use-technologies";
import { compact } from 'lodash';
import { Organisation } from "@shapeable/collaborate-ore-types";


export type MarketMapFiltersVariant = 'explorer-community' | 'innovation-community';
export const useMarketMapFilters = (page?: MarketMapFiltersVariant) => {
  const allEconomies = useEconomies();
  const allOrganisationTypes = useOrganisationTypes();
  const allTechnologies = useTechnologies();

  return {
    selects: compact([
      {
        name: 'technologies',
        items: allTechnologies,
        pluralLabel: 'Technologies',
        getResultFieldSlugs: (result: Organisation) => result.technologies?.map(t => t.slug),
      },
      page === 'explorer-community' && {
        name: 'countries',
        items: allEconomies,
        pluralLabel: 'Countries',
      },
      {
        name: 'type',
        items: allOrganisationTypes,
        pluralLabel: 'Stakeholder Types',
        getResultFieldSlugs: (result: Organisation) => [result.type?.slug],
      },
    ]),

  };
}