import { EnergyIcon } from "../components/icons/energy-icon";
import { COLOR_VALUE_ENERGY } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendEnergy: TrendNodeSpec = {
  width: 158,
  height: 142,
  x: 104,
  y: 89,
  slug: 'energy',
  color: COLOR_VALUE_ENERGY,
  icon: {
    x: 61.28, y: 29, width: 35, height: 43,
    component: EnergyIcon,
  },
  bubble: {
    text: [
      { x: 44.3672, y: 135.832, text: 'Energy' },
    ],
    x: 28,
  },
  smallBubble: {
    x: 183.26, y: 139.63,
    text: [
      { x: 117.922, y: 127.25, text: 'Energy' },
    ],
  },
  topicNodes: [
    // {
    //   slug: 'slug',
    //   text: [ 
    //     { x: 0, y: 0, text: 'TEXT' },
    //   ],
    //   bubble: { x: 0, y: 0 },
    //   path: "PATH",
    // },
  ],
};
