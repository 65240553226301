import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, EconomiesProvider as Provider } from '@shapeable/web';
import { sortBy } from 'lodash';
import { Economy } from '@shapeable/collaborate-ore-types';

const ECONOMIES_QUERY = graphql`
  query EconomiesProvider {
    platform {
      allEconomies {
        __typename _schema { label pluralLabel }
        id path slug name
        depositsCount
        openGraph { image { url thumbnails { small { url } } } }
      }
    }
  }
`;

export const EconomiesProvider: React.FC<any> = ({ children }) => {
  const economies: Economy[] = platformProviderValue( useStaticQuery(ECONOMIES_QUERY), 'allEconomies' );
  // filter out economies with no deposits  

  const value = economies.filter(economy => !!economy.depositsCount);
  return <Provider value={ value }>{children}</Provider>;
};