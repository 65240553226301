import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, IconComponentProps, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/web';
const cls = classNames('trend-bubble-dot');

import { darken } from 'polished';

// -------- Types -------->

export type TrendBubbleDotProps = Classable & HasChildren & {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  isActive?: boolean;
  color?: string;
}

export const TrendBubbleDotDefaultProps: TrendBubbleDotProps = {
  color: '#333333',
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color: string;

}

type CircleProps = {
}


// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    overflow: visible;
    ${({ _color }: ContainerProps ) => css`
      transition: all 0.3s;
      ${cls.dot('dot')} {
        fill: ${_color}
      }
    `}

  `,
});

const CircleStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled.svg<ContainerProps>`${ContainerStyles}`,
  Circle: styled.circle`${CircleStyles}`,
};

export const TrendBubbleDot: Shapeable.FC<TrendBubbleDotProps> = (props) => {
  const { className, color, x, y, width, height, isActive } = props;
  return (
    <My.Container  _color={color} x={x} y={y} width={width} height={height} className={cls.name(className)} viewBox="0 0 102 102">
    <g className={cls.sub('bubble')}>
    <My.Circle cx="51" cy="51" r="51" className={cls.sub('dot')}></My.Circle>
    <g className={cls.sub('decoration-dots')}>
    <circle cx="50.9901" cy="16.08" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="62.8402" cy="18.1601" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="73.2802" cy="24.1898" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="80.9999" cy="33.4101" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="85.1098" cy="44.7099" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="85.12" cy="56.7499" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="80.9999" cy="68.0702" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="73.2602" cy="77.2802" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="62.85" cy="83.3002" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="50.9901" cy="85.39" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="39.1298" cy="83.2899" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="28.7299" cy="77.2899" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="20.9799" cy="68.0702" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="16.85" cy="56.7499" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="16.8598" cy="44.7201" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="20.9799" cy="33.4101" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="28.7099" cy="24.1801" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    <circle cx="39.1498" cy="18.1698" r="3.85" fill="white" fill-opacity="0.25" className={cls.sub('decoration-dot')}></circle>
    </g>
    </g>
    </My.Container>
  )
};

TrendBubbleDot.cls = cls;
TrendBubbleDot.defaultProps = TrendBubbleDotDefaultProps;