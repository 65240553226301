import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, useLang } from '@shapeable/web';
const cls = classNames('summary-card-items');

// -------- Types -------->

export type SummaryItemProps = Classable & HasChildren & { 
  label?: string;
  count?: number;
};

export const SummaryItemDefaultProps: Omit<SummaryItemProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    font-family: ${theme.FONT('sans')};
  `,
});

const ItemStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
});

const LabelStyles = breakpoints({
  base: css`
    overflow: hidden;
    color: var(--shapeable-text, #5A5A5A);
    text-overflow: ellipsis;
    font-size: ${24 / 16}em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  
  `,
});

const NumberStyles = breakpoints({
  base: css`
    color: #F47B20;
    font-size: ${24 / 16}em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Item: styled.div`${ItemStyles}`,
      Label: styled.span`${LabelStyles}`,
      Number: styled.span`${NumberStyles}`,
};

export const SummaryItem: Shapeable.FC<SummaryItemProps> = (props) => {
  const { className, children, label, count } = props;

  const t = useLang();
  
  return (
   <My.Container className={cls.name(className)}>
    <My.Item>
      <My.Label>{label || children}</My.Label>
      {
        count !== undefined &&
        <My.Number>{count}</My.Number>
      }
    </My.Item>
   </My.Container>
  )
};

SummaryItem.defaultProps = SummaryItemDefaultProps;
SummaryItem.cls = cls;