import { AirIcon } from "../components/icons/air-icon";
import { MaterialMovementIcon } from "../components/icons/material-movement-icon";
import { COLOR_VALUE_AIR } from "../theme";
import { TrendNodeSpec } from "./types";

export const trendAir: TrendNodeSpec = {
  width: 158,
  height: 143,
  x: 15,
  y: 304,
  slug: 'air',
  color: COLOR_VALUE_AIR,
  icon: {
    x: 56, y: 29, width: 45, height: 45,
    component: AirIcon,
  },
  bubble: {
    text: [
      { x: 63.8202, y: 136.829, text: 'Air' },
    ],
    x: 28,
    y: 0,
  },
  smallBubble: {
    x: 94.33, y: 354.329,
    text: [
      { x: 48.666, y: 359.25, text: 'Air' },
    ],
  },
  topicNodes: [
    // {
    //   slug: 'slug',
    //   text: [ 
    //     { x: 0, y: 0, text: 'TEXT' },
    //   ],
    //   bubble: { x: 0, y: 0 },
    //   path: "PATH",
    // },
  ],
};
