import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, classNames, useCitations, useFeedEntries, useLang, useOrganisations, usePeople } from '@shapeable/web';
import { SummaryCard } from './summary-card';
import { useTrendsExplorer } from '../../hooks/use-trends-explorer';
import { ValueChainMap } from '../elements/value-chain-map';
import { useInnovations } from '../../hooks/use-innovations';
import { useCommodityGroups } from '../../hooks/use-commodity-groups';
import { PAGE_EXPLORER } from '../../data';
import { useQuery, gql } from '@apollo/client';
const cls = classNames('explorer-summary-layout');

import { first } from 'lodash';
import { useCatalysts } from '../../hooks/use-catalyst';


// -------- Types -------->

export type EntitySummaryItem = {
  label?: string;
  count?: number;
};

export type ExplorerSummaryItem = {
  title?: string;
  path?: string;

  items?: EntitySummaryItem[];
  element?: React.ReactNode;
};

// -------- Props -------->

export type ExplorerSummaryLayoutProps = Classable & HasChildren & {
  entity: Entity;
}

export const ExplorerSummaryLayoutDefaultProps: Omit<ExplorerSummaryLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
}

type BgProps = {
  _bg: string;
}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} 0;
  `,
  desktop: css`
    padding: ${theme.UNIT(5)};
  `,
});


const BgStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0;

    ${({ _bg }: BgProps ) => _bg && css`
      background: url(${_bg}) no-repeat;
      background-size: cover;
    `}
  `,
  tablet: css`
    padding: ${theme.UNIT(4)};
  `,
  desktop: css`
    padding: ${theme.UNIT(5)};
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;
  `,
  desktop: css`
    flex-direction: row;
  `,
});

const ColumnStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: stretch;
    flex: 1 0 0;
    a {
      text-decoration: none;
    }
  `,
});



const HeaderStyles = breakpoints({
  base: css`
    color: #FFF;
    font-family: IBM Plex Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.24px;
    margin-top: 0;
    padding: 0 ${theme.UNIT(3)};
  `,
});



const SummaryCardStyles = breakpoints({
  base: css`
    
  `,
});

const MapStyles = breakpoints({
  base: css`
    width: 100%;
    height: auto;
    margin-bottom: ${theme.UNIT(9)};
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Bg: styled.div<BgProps>`${BgStyles}`,
    Header: styled.h1`${HeaderStyles}`,
    Body: styled.div`${BodyStyles}`,
    Column: styled.div`${ColumnStyles}`,
    SummaryCard: styled(SummaryCard)`${SummaryCardStyles}`,

    Map: styled(ValueChainMap)`${MapStyles}`,

};

export const ExplorerSummaryLayout: Shapeable.FC<ExplorerSummaryLayoutProps> = (props) => {
  const { className, entity } = props;


  const { banners } = entity;

  const banner = first(banners);

  const t = useLang();

  const innovations = useInnovations();
  const commodityGroups = useCommodityGroups();
  const organisations = useOrganisations();
  const people = usePeople();
  const feedEntries = useFeedEntries();
  const citations = useCitations();
  const catalysts = useCatalysts();

  const { loading, data } = useQuery(
    gql`
      query {
        listPages(filter: { types: { in: ["impact-strategy"]} }) {
          results {
            id name 
          }
        }
        allFileAssets {
          id name 
        }
        allPosts {
          id name 
        }
      }
  `);

  const pages = data?.listPages?.results || [];
  const files = data?.allFileAssets || [];
  const posts = data?.allPosts || [];
  
  const dataListLeft: ExplorerSummaryItem[] = [
    {
      title: t('Value Chain'),
      path: `${PAGE_EXPLORER.path}#view=value-chain`,
      element: <My.Map />,
    },
    {
      title: t('Commodities:'),
      path: `${PAGE_EXPLORER.path}#view=innovations`,
      items: [
        {
          label: t('Commodity Groups'),
          count: commodityGroups.length,
        }
      ]
    }];

    const dataListRight: ExplorerSummaryItem[] = [
    {
      title: t('Innovations'),
      path: `${PAGE_EXPLORER.path}#view=innovations`,
      items: [
        {
          label: t('Innovations'),
          count: innovations.length,
        }
      ],
    },
    {
      title: t('Impact Strategies'),
      path: `${PAGE_EXPLORER.path}#view=impact-strategies`,
      items: [
        {
          label: t('Catalysts'),
          count: catalysts.length,
        },
        {
          label: t('Pages'),
          count: pages.length,
        },
      ]
    },
    {
      title: t('Community'),
      path: `${PAGE_EXPLORER.path}#view=community`,
      items: [
        {
          label: t('Organisations'),
          count: organisations.length,
        },
        {
          label: t('People'),
          count: people.length,
        }
      ]
    },
    {
      title: t('Stakeholder Map'),
      path: `${PAGE_EXPLORER.path}#view=stakeholder-map`,
      items: [
        {
          label: t('Organisations'),
          count: organisations.length,
        }
      ]
    },
    {
      title: t('Library'),
      path: `${PAGE_EXPLORER.path}/#view=library`,
      items: [
        {
          label: t('Scientific Papers'),
          count: citations.length,
        },
        {
          label: t('Reports'),
          count: files.length,
        },

      ]
    }
  ];


  return (
    <My.Container className={cls.name(className)}>
      <My.Bg _bg={banner?.image?.url}>
      <My.Header>{t('CollaborateOre contains the following eight areas of content and data.')}</My.Header>
      <My.Body>
      <My.Column>
      {
        dataListLeft.map((item, index) => {
          return (
            <My.SummaryCard key={index} title={item.title} path={item.path} items={item.items}>
            {item.element}
            </My.SummaryCard>
          );
        })
      }
      </My.Column>
      <My.Column>
      {
        dataListRight.map((item) => {
          return (
            <My.SummaryCard title={item.title} path={item.path} items={item.items}>
 
            </My.SummaryCard>
          );
        })
      }
      </My.Column>

      </My.Body>
      </My.Bg>
    </My.Container>
  )
};

ExplorerSummaryLayout.cls = cls;
ExplorerSummaryLayout.defaultProps = ExplorerSummaryLayoutDefaultProps;