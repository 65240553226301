import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, Entity, HasChildren, Shapeable, Trend } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { OutlineEntityDotLink, classNames, useEntity } from '@shapeable/web';
import { Topic } from '@shapeable/collaborate-ore-types';
const cls = classNames('topics-embed');

import { sortBy } from 'lodash';

// -------- Types -------->

export type EntityWithTopics = Entity & {
  topics?: Topic[];
}

export type TopicsEmbedProps = Classable & HasChildren & { 
};

export const TopicsEmbedDefaultProps: TopicsEmbedProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const ItemStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Item: styled(OutlineEntityDotLink)<ContainerProps>`${ItemStyles}`,

};

export const TopicsEmbed: Shapeable.FC<TopicsEmbedProps> = (props) => {
  const { className, children } = props;
  
  // embeds the topics for the current trend

  const entity = useEntity() as Trend;

  return (
    <My.Container className={cls.name(className)}>
    {
      sortBy(entity.topics, 'outlineNumber').map(topic => <My.Item entity={topic} key={entity.id} />)
    }
    </My.Container>
  )
};

TopicsEmbed.defaultProps = TopicsEmbedDefaultProps;
TopicsEmbed.cls = cls;