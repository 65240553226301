import { useHorizonTypes } from "@shapeable/web";
import { useCatalysts } from "./use-catalyst";
import { useTechnologies } from "./use-technologies";
import { useTrendsExplorer } from "./use-trends-explorer";
import { upperFirst, compact } from 'lodash';
import { HorizonType, Innovation } from "@shapeable/collaborate-ore-types";

export type InnovationsFiltersVariant = 'explorer' | 'topic';
export const useInnovationsFilters = (variant?: InnovationsFiltersVariant) => {

  const variantToUse = variant || 'explorer';

  const allTrends = useTrendsExplorer();
  const allTechnologies = useTechnologies();
  const allCatalysts = useCatalysts();
  const allHorizonTypes = useHorizonTypes().map(horizonType => ({
    ...horizonType,
    name: upperFirst(horizonType.subtitle),
  })) as HorizonType[];

  return { 
    selects: compact([
      variantToUse === 'explorer' && {
        name: 'themes',
        fieldName: 'trends',
        items: allTrends,
        pluralLabel: 'Value Chain Themes',
      },
      {
        name: 'horizonTypes',
        items: allHorizonTypes,
        pluralLabel: 'Horizon Types',
        getResultFieldSlugs: (result: Innovation) => result.horizons.map(horizon => horizon.type?.slug),
      },
      {
        name: 'technologies',
        items: allTechnologies,
        pluralLabel: 'Technologies',
      },
      {
        name: 'catalysts',
        items: allCatalysts,
        pluralLabel: 'Catalysts',
      },
    ])
  };
};