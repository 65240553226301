import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/web';
const cls = classNames('explorer-view');

// -------- Types -------->

export type ExplorerViewProps = Classable & HasChildren & {
}

export const ExplorerViewDefaultProps: ExplorerViewProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(3)};
    padding: ${theme.UNIT(3)} ${theme.UNIT(4)};
  `,
  desktop: css`
    gap: ${theme.UNIT(4)};
    padding: ${theme.UNIT(3)} ${theme.UNIT(6)};
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
};

export const ExplorerView: Shapeable.FC<ExplorerViewProps> = (props) => {
  const { className, children } = props;
  return (
    <My.Container className={cls.name(className)}>
    {children}
    </My.Container>
  )
};

ExplorerView.cls = cls;
ExplorerView.defaultProps = ExplorerViewDefaultProps;