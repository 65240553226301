import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityBubbleImage, EntityLink, Icon, Icons, classNames, useEntity, useLang, useLink } from '@shapeable/web';
import { Innovation } from '@shapeable/collaborate-ore-types';
import { SummaryItem } from './summary-item';
import { EntitySummaryItem } from './explorer-summary-layout';
const cls = classNames('summary-card');

// -------- Types -------->

export type SummaryCardProps = Classable & HasChildren & { 
  title?: string;
  path?: string;
  items: EntitySummaryItem[];

};

export const SummaryCardDefaultProps: Omit<SummaryCardProps, 'entity'> = {
  items: [],
};

// -------- Child Component Props -------->

type ContainerProps = {
  
}

type LinkProps = { 
  icon?: boolean;
}

// -------- Styles -------->

// **Change styling for container for when a view variant is put in**
const ContainerStyles = breakpoints({
  base: css`
    font-size: 0.8em;
    background-color: var(--shapeable-light, #ffffffaa);
  `,
  tablet: css`
    font-size: 0.9em;
  `,
  desktop: css`
    font-size: 1em;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    padding: ${theme.UNIT(3)};
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    flex-shrink: 0;
  `,
  desktop: css`
    padding: ${theme.UNIT(6)};
  `
});

const TitleAndIconStyles = breakpoints({
  base: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: var(--shapeable-strong, #000);
    font-family: IBM Plex Sans;
    font-size: ${36/16}em;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.72px;
  `,
});

const IconStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-end;
    flex: 1 0 0;
    align-self: stretch;
    object-fit: none;

  ${({ icon }: LinkProps) => (icon && css`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  `)}
  `,
});

const ItemsStyles = breakpoints({
  base: css`

  `,
});

const ImageContainerStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  `,
});

const ImageStyles = breakpoints({
  base: css`
    height: 745px;
    flex: 1 0 0;
  `,
});






// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
   Layout: styled.div<LinkProps>`${LayoutStyles}`,
    TitleAndIcon: styled.div`${TitleAndIconStyles}`,
      Title: styled.span`${TitleStyles}`,
      Icon: styled(Icon)`${IconStyles}`,
    Body: styled.div<LinkProps>`${BodyStyles}`,
      Item: styled(SummaryItem)`${ItemsStyles}`,

      ImageContainer: styled.div`${ImageContainerStyles}`,
        Image: styled(EntityBubbleImage)`${ImageStyles}`,
};

export const SummaryCard: Shapeable.FC<SummaryCardProps> = (props) => {
  const { className, children, title, path, items } = props;

  const { Link } = useLink();

  return (
    <My.Container className={cls.name(className)}>
      <Link href={path}>
      <My.Layout>
      <My.TitleAndIcon>
        <My.Title>{title}</My.Title>
        <My.Icon size={20}><Icons.ChevronRight /></My.Icon>
      </My.TitleAndIcon>

      {
        !children &&
        <My.Body>
        {
           (
            items.map(({ label, count }) => <My.Item label={label} count={count} />)
          )
        }
        </My.Body>
      }
    
      </My.Layout>
      </Link>
      {children}
    </My.Container>
  )
};

SummaryCard.defaultProps = SummaryCardDefaultProps;
SummaryCard.cls = cls;