import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue, PeopleProvider as Provider } from '@shapeable/web';

const QUERY = graphql`
  query PeopleQuery {
    platform {
      allPeople {
        id slug name __typename _entityTypeName
        bio { plain }
        organisation { name }
        photo { id url }
        position
        economies { slug name }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        linkedin
      }
    }
  }
  `

export const PeopleProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allPeople' ) }>{children}</Provider>;
}