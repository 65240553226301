import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react'; // tslint:disable-line
import { platformProviderValue } from '@shapeable/web';
import { TrendsExplorerProvider as Provider } from '../../components/context/trends-explorer-context';

const QUERY = graphql`
  query TrendsExplorerQuery {
    platform {
      allTrends { 
        __typename _schema { label pluralLabel }
        id name path outlineNumber slug _entityTypeName
        color { id name value }
        openGraph {
          title date description { plain }
          image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
          thumbnails { full { url } }
        }
        }
        topics {
          __typename _schema { label pluralLabel }
          id name path outlineNumber slug
          color { id name value }
          openGraph {
            title date description { plain }
            image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            thumbnails { full { url } }
          }
          }
          subTopics {
            __typename _schema { label pluralLabel }
            id name path outlineNumber slug
            color { id name value }
            openGraph {
              title date description { plain }
              image { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } }
            }
          }
        }
      }
    }
  }
`;

export const TrendsExplorerProvider: React.FC<any> = ({ children }) => {
  return <Provider value={ platformProviderValue( useStaticQuery(QUERY), 'allTrends' ) }>{children}</Provider>;
};